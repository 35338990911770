<template>
  <div class="outer-container">
    <h1 class="title">iFrame-builder</h1>

    <br>

    <p>Select Topic:</p>
    <select v-model="selectedTopicID">
      <option disabled value="">Please select one</option>
      <option value="999">All Topics</option>
      <template v-for="option in topicsList">
        <option :key="option.topicID" :value="option.topicID">{{option.topicName}}</option>
      </template>
    </select>

    <br>

    <p>Allow User to select periods?</p>
    <input type="checkbox" name="selectPeriods" v-model="allowPeriodSelection">

    <p v-if="!allowPeriodSelection">Select Period:</p>
    <div class="select-wrapper" v-if="!allowPeriodSelection">
      <select v-model="selectedPeriod">
        <option disabled value="">Please select one</option>
        <template v-for="option in periodsList">
          <option :key="option.id" :value="option.id">{{option.name}}</option>
        </template>
      </select>
    </div>

    <br>

    <p>Select Section:</p>
    <select v-model="selectedSection">
      <option disabled value="">Please select one</option>
      <template v-for="option in sectionsList">
        <option :key="option">{{option}}</option>
      </template>
    </select>

    <br>

    <p>Enter Title and description</p>

    <input type="text" v-model="title" placeholder="Title">

    <br>
    <br>

    <textarea v-model="description" placeholder="Description"></textarea>

    <br>
    <br>

    <a :href="link" target="blank" v-if="link">
      <button>Open iFrame</button>
    </a>
    <br>

    <p v-if="link">Link: <a :href="link" target="blank">{{hostname}}{{link}}</a></p>
  </div>
</template>

<style lang="scss" scoped>
.outer-container {
  margin-top: -60px;
}
</style>

<script>
import axios from "axios";
export default {
  data() {
    return {
      selectedTopicID: null,
      selectedPeriod: null,
      selectedSection: null,
      title: "Test Title",
      description:
        "This is an iFrame-Test. It can be embedded in an iFrame anywhere and it will automatically rescale (when it is finished).",
      periodsList: [
        { id: "period1", name: "2011 - 2014" },
        { id: "period2", name: "2014 - 2015" },
        { id: "period3", name: "2015 - 2016" },
        { id: "period4", name: "2016 - 2018" },
        { id: "allPeriods", name: "All Periods" }
      ],
      sectionsList: ["SectionVotesParty", "SectionAlliances"],
      topicsList: [],
      allowPeriodSelection: true
    };
  },
  computed: {
    hostname: function() {
      return window.location.hostname;
    },
    link: function() {
      if (this.allowPeriodSelection) {
        if (
          this.selectedTopicID !== null &&
          this.selectedSection &&
          this.title &&
          this.description
        ) {
          return `/iframe/${this.selectedTopicID}/selectPeriod/${
            this.selectedSection
          }?title=${this.title}&description=${this.description}`;
        } else {
          return "";
        }
      } else {
        if (
          this.selectedTopicID !== null &&
          this.selectedPeriod &&
          this.selectedSection &&
          this.title &&
          this.description
        ) {
          return `/iframe/${this.selectedTopicID}/${this.selectedPeriod}/${
            this.selectedSection
          }?title=${this.title}&description=${this.description}`;
        } else {
          return "";
        }
      }
    }
  },
  mounted() {
    axios
      .get("/static/overview.json")
      .then(
        response =>
          (this.topicsList =
            response.data.SectionOverviewStatsAll.govPeriods.allPeriods)
      );
  }
};
</script>
