<template>
  <div class="iframe-outer-container">
    <div class="inner-container">
      <div class="side-bar">
        <h1 class="title">{{$route.query.title}}</h1>
        <p>{{$route.query.description}}</p>
        <div class="period-btns" v-if="$route.params.period === 'selectPeriod'">
          <button @click="setSelectedPeriod('allPeriods')" :style="period === 'allPeriods'?'background-color: #2e1d86;':''">Alle Perioder</button>
          <button @click="setSelectedPeriod('period1')" :style="period === 'period1'?'background-color: #2e1d86;':''">2011 - 2014</button>
          <button @click="setSelectedPeriod('period2')" :style="period === 'period2'?'background-color: #2e1d86;':''">2014 - 2015</button>
          <button @click="setSelectedPeriod('period3')" :style="period === 'period3'?'background-color: #2e1d86;':''">2015 - 2016</button>
          <button @click="setSelectedPeriod('period4')" :style="period === 'period4'?'background-color: #2e1d86;':''">2016 - 2018</button>
        </div>
      </div>
      <div class="main-area">
        <SectionSpeakersRight v-if="selectedSection === 'SectionSpeakers'" :sectionData="topicData.SectionSpeakers" :fixedPeriod="selectedPeriod" />
        <template v-if="$route.params.topicID !== '999'">
          <SectionVotesPartyRight v-if="selectedSection === 'SectionVotesParty'" :sectionData="topicData.SectionVotesParty" :fixedPeriod="selectedPeriod" />
        </template>
        <template v-else>
          <SectionVotesPartyRight v-if="selectedSection === 'SectionVotesParty'" :sectionData="overviewData.SectionOverviewVotesParty" :fixedPeriod="selectedPeriod" />
        </template>
        <SectionVotesGridRight v-if="selectedSection === 'SectionVotesGrid'" :sectionData="topicData.SectionVotesGrid" :fixedPeriod="selectedPeriod" />
        <template v-if="$route.params.topicID !== '999'">
          <SectionAlliancesRight v-if="selectedSection === 'SectionAlliances'" :sectionData="topicData.SectionAlliances" :averageData="overviewData.SectionOverviewAlliances" :fixedPeriod="selectedPeriod" />
        </template>
        <template v-else>
          <SectionAlliancesRight v-if="selectedSection === 'SectionAlliances'" :sectionData="overviewData.SectionOverviewAlliances" :averageData="overviewData.SectionOverviewAlliances" :fixedPeriod="selectedPeriod" :onlyAverage="true" />
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

body {
  font-size: 1vw;
}
.iframe-outer-container {
  position: relative;
  width: 100%;
  padding-top: 65%;
  margin-top: -60px;
  overflow: hidden;
  background: white;
  .inner-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    .side-bar {
      width: 35%;
      padding: 2%;
      height: 100%;
      background: black;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      h1.title {
        margin-top: 0;
      }
      p {
        font-family: "roboto-condensed";
        font-weight: 500;
      }
      .period-btns {
        margin-top: 10px;
        button {
          border: none;
          background-color: #6388c3;
          font-family: "futura-pt-condensed";
          font-weight: 700;
          font-size: 1vw;
          color: #fff;
          text-transform: uppercase;
          border-radius: 2px;
          padding: 5px 7px;
          margin-left: 7px;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
    }
    .main-area {
      width: 65%;
      height: 100%;
    }
  }
}

@media (max-width: $bp-narrow) {
  .side-bar {
    font-size: 1vw !important;
  }
  .main-area {
    font-size: 2vw !important;
  }
  p {
    font-size: 160%;
  }
  button {
    font-size: 160% !important;
  }
}
</style>

<script>
import axios from "axios";
import SectionSpeakersRight from "@/sections/SectionSpeakers/SectionRight";
import SectionVotesPartyRight from "@/sections/SectionVotesParty/SectionRight";
import SectionVotesGridRight from "@/sections/SectionVotesGrid/SectionRight";
import SectionAlliancesRight from "@/sections/SectionAlliances/SectionRight";
export default {
  name: "iFrame",
  components: {
    SectionSpeakersRight,
    SectionVotesPartyRight,
    SectionVotesGridRight,
    SectionAlliancesRight
  },
  data() {
    return {
      topicData: null,
      overviewData: null,
      period: "allPeriods"
    };
  },
  methods: {
    setSelectedPeriod: function(period) {
      this.period = period;
      this.$eventBus.$emit("selectedPeriod", period);
    }
  },
  computed: {
    selectedSection: function() {
      return this.$route.params.section;
    },
    selectedPeriod: function() {
      if (this.$route.params.period === "selectPeriod") {
        return this.period;
      } else {
        return this.$route.params.period;
      }
    }
  },
  beforeMount() {
    if (this.$route.params.topicID !== "999") {
      let path = `/static/topic${this.$route.params.topicID}.json`;
      axios
        .get(path)
        .then(response => (this.topicData = response.data.topicData));
    } else {
      let path = `/static/topic${this.$route.params.topicID}.json`;
      axios
        .get(path)
        .then(response => (this.topicData = response.data.topicData));
    }
    axios.get(`/static/overview.json`).then(response => {
      this.overviewData = response.data;
    });
  }
};
</script>
